/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --secondary-color: #c27380;
  --dark-color: #0a1416;
  /* #343a40; */
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --disabled-color: #c4bbbb;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.img-logo {
  content: url("./img/logo.svg");
  width: 40px;
}

.brand-logo {
  height: 35px
}

.brand-logo h1 {
  font-size: 1.5rem;
}

.logo-big {
  content: url("./img/logo.svg");
  width: 250px;
  margin-top: 50px;
}

.search-panel {
  display: flex;

}

.input-text {
  display: inline;
  /* width: 100%; */
  padding: 0.2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.container-top {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
}

.container-bottom {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-bottom: 3rem;
}

.inline {
  display: inline-block;
}

/* .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
} */

#footer {
  position: fixed;
  height: 20px;
  bottom: 0px;
  /* left: 0px; */
  right: 20px;
  margin-bottom: 5px;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer p {
  font-size: 0.75rem;
  color: #fff;
}

.footer-basic {
  padding: 30px 0;
  background-color: #ffffff;
  color: #4b4c4d;
}

.footer-home {
  padding: 10px;
  background-color: #ffffff;
  color: #4b4c4d;
  height: 20vh;
  border-top: solid 1px var(--dark-color);
  opacity: 0.9;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-home ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-home li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-home ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-home ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-home .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-home .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social>a:hover {
  opacity: 0.9;
}

.footer-home .social>a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 7px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.footer-home .copyright {
  margin-top: 7px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.medium {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.small {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.small2 {
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.tiny {
  font-size: 0.90rem;
  line-height: 1;
  justify-items: center;
}

.mini {
  font-size: 0.80rem;
  line-height: 1;
  justify-items: center;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.title-search-result {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Search Container and Ctrl */
.search {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.textinput {
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  display: block;
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mg-top-1 {
  margin-top: 1rem;
}

/* Buttons */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 4px;
}

.btn-small {
  font-size: 0.8rem
}

.btn-disabled {
  background: var(--disabled-color);
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  width: 160px;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-secondary {
  background: var(--secondary-color);
  color: #fff
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.round-img-slow {
  border-radius: 2%;
  width: 250px;
  /*height: 250px;*/
  object-fit: contain;
}


.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.darklight-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
}

/* .darklight-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none;
}

.form input[type='checkbox'] {
  /* display: block;
  width: 100%;
  padding: 0.4rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none; */
  margin: 10px;
}

/* .form input[type='submit'],
button {
  font: inherit;
} */

.form input[type='submit']:disabled {
  background: var(--disabled-color);
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}

.form .social-input i.fa-facebook {
  color: #3b5998;
}

.form .social-input i.fa-instagram {
  color: #3f729b;
}

.form .social-input i.fa-youtube {
  color: #c4302b;
}

.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

.table tfoot td {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
  white-space: nowrap;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  background-image: url('./img/showcase2.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Company */
.company {
  position: relative;
  background: url('./img/company.jpg') no-repeat center center/cover;
  min-height: 100vh;
}

.company-inner {
  color: #fff;
  min-height: 100vh;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.company-container {
  margin-top: 4.0rem;
}

/* Conditions */
.conditions {
  position: relative;
  min-height: 100vh;
}

.conditions-inner {
  min-height: 100vh;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conditions-container {
  margin-top: 4.0rem;
}

.conditions-container p {
  margin-top: 1.2rem;
  line-height: 1.6;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: top;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp>div,
.profile-edu>div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp>div:last-child,
.profile-edu>div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo>div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github>div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post>div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}


/* Notebooks Styles */
/* @media (max-width: 900px) {
  .logo-big {
    width: 150px;
  }
} */

/* Mobile Styles */
@media (max-width: 700px) {
  .logo-big {
    width: 150px;
  }

  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 2rem;
  }

  .large {
    font-size: 1.6rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }

  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

  .round-img-slow {
    border-radius: 2%;
    width: 150px;
    height: 150px;
  }

  .img-logo {
    /* content:url("./img/logo.svg"); */
    width: 30px;
  }

  .brand-logo h1 {
    font-size: 1.25rem;
  }

}

.alert-wrapper {
  position: fixed;
  top: 4rem;
  right: 2rem;
  display: inline-block;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.btn-link {
  line-height: 1;
}

/* cards styles */
.cards {
  padding: 2rem;
}

.cards>ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.cards>ul>li {
  border: 1px solid #E2E2E2;
  border-radius: .5rem;
}

.cards>ul>li>figure {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.cards>ul>li>figure>img {
  width: 100%;
}

.cards>ul>li>figure>figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  width: 100%;
}

.cards>ul>li>figure>figcaption>h3 {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.cards>ul>li>figure>figcaption>h3>a {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.cards>ul>li>p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}

.cards>ul>li>a {
  padding: .5rem 1rem;
  margin: .5rem;
}

.card-load {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.card-load>figure>img {
  /*width: 100%;*/
  width: 300px;
}


/* gral*/
.no-wrap {
  white-space: nowrap;
  min-width: max-content;
}

p {
  white-space: pre-wrap;
}

label {
  font-weight: 550;
  font-size: 1.1rem;
}

.inline {
  display: inline-block;
}

.link {
  cursor: pointer;
  margin: 0 0.25rem;
}

.footer2 {
  padding: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.rubber-stamp {
  background: var(--dark-color);
  color: #fff
}

/* end gral*/

/* Nav Calendar*/
/* .nav-months-new {
  border: #38a1f3 1px solid;
}

.nav-months-new ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.nav-months-new li {
  margin: 5px;
}

.nav-months-new a {
  width: 110px;
  text-align: center;
  padding: .3em 0.9em;
  text-decoration: none;
  color: #333;
  background: var(--white-color);
  border: #ccc solid 1px;
  cursor: pointer;
  border-radius: 4px;
} */

/***********************************/
.nav-months ul {
  float: left;
  list-style: none;
}

.nav-months li {
  float: left;
  margin: 3px;
  border-radius: 4px;
}

.nav-months a {
  width: 110px;
  text-align: center;
  float: left;
  font-size: 0.9rem;
  padding: .2em 0.7em;
  text-decoration: none;
  color: #333;
  background: var(--white-color);
  border: #ccc solid 1px;
  cursor: pointer;
  border-radius: 4px;
}

.nav-months a:hover,
nav a:focus {
  outline: 0;
  color: #fff;
  background: var(--dark-color);
}

.nav-months ul li a.active {
  color: #fff;
  background: var(--success-color);
}

.nav-months ul li a.disabled {
  color: #333;
  background: var(--light-color);
  border: #eee solid 1px;
}

/* End Nav Calendar*/

.highlight {
  color: white;
  background-color: #343a40;
  font-weight: 900;
  padding: 4px;
  opacity: 0.9;
}

.highlight2 {
  color: white;
  background-color: #F15A2A;
  font-weight: 900;
  padding: 4px;
  opacity: 0.9;
}

.footnote {
  font-size: 13px;
}

.text-link {
  font-size: 0.75rem;
  color: #fff;
}

/* @media (max-width: 768px) {
  .logo-big {
    width: 250px;
  }
  .x-large {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .large {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .medium {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }  

} */
/********************************************************************/
/* Notebooks Styles */
/********************************************************************/
@media (max-width: 1366px) {
  .logo-big {
    width: 200px;
    margin-top: 60px;
  }
  .x-large {
    font-size: 2.6rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .large {
    font-size: 2.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .medium {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }  
  .lead {
    font-size: 1.8rem;
  }
}

/********************************************************************/
/* Mobile Styles */
/********************************************************************/
@media (max-width: 700px) {
  .logo-big {
    width: 150px;
  }

  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 2rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }

  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

  .round-img-slow {
    border-radius: 2%;
    width: 150px;
    height: 150px;
  }

  .img-logo {
    /* content:url("./img/logo.svg"); */
    width: 30px;
  }

  .brand-logo h1 {
    font-size: 1.25rem;
  }

}
/********************************************************************/
/* End Mobile Styles */
/********************************************************************/